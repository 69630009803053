import React from 'react';
import styles from './SearchBar.module.css';

function SearchBar({ searchTerm, setSearchTerm }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    // The actual filtering is handled in the parent component
  };

  return (
    <form className={styles.searchBarContainer} onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Type your search term here..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.searchInput}
      />
      <button type="submit" className={styles.searchButton}>
        Search
      </button>
    </form>
  );
}

export default SearchBar;
