import React from 'react';
import ProjectCard from './ProjectCard';
import './HomePage.css';  // Make sure this import is present

function HomePage({ projects, searchTerm }) {
  return (
    <div className="home-page">
      {!searchTerm && (
        <div className="no-search-message">
          Enter a search term to display projects.
        </div>  
      )}
      {searchTerm && projects.length === 0 && (
        <div className="no-results-message">
          No results found for "{searchTerm}".
        </div>
      )}
      <div className="innovation-project-grid">
        {projects.map(project => (
          <ProjectCard key={`${project.id}-${searchTerm}`} project={project} />
        ))}
      </div>
    </div>
  );
}

export default HomePage;
