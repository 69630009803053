// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #6c757d;
  text-align: left;
}

.about-info-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.about-info {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.about-info-item {
  margin-bottom: 1rem;
}

.about-info-label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
}

.about-feature-list {
  padding-left: 20px;
  margin-top: 0.5rem;
}

.about-feature-list li {
  margin-bottom: 0.5rem;
}

.about-info a {
  color: #007bff;
  text-decoration: none;
}

.about-info a:hover {
  text-decoration: underline;
}

.search-count {
  font-size: 1.2em;
  font-weight: bold;
  color: #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/components/AboutPage.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,wCAAwC;EACxC,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".about-page {\r\n  max-width: 800px;\r\n  margin: 0 auto;\r\n  padding: 20px;\r\n  color: #6c757d;\r\n  text-align: left;\r\n}\r\n\r\n.about-info-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 1rem;\r\n  max-width: 800px;\r\n  margin: 0 auto;\r\n}\r\n\r\n.about-info {\r\n  background-color: #ffffff;\r\n  padding: 1.5rem;\r\n  border-radius: 8px;\r\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);\r\n  text-align: left;\r\n}\r\n\r\n.about-info-item {\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.about-info-label {\r\n  font-weight: bold;\r\n  display: block;\r\n  margin-bottom: 0.5rem;\r\n  color: #333;\r\n}\r\n\r\n.about-feature-list {\r\n  padding-left: 20px;\r\n  margin-top: 0.5rem;\r\n}\r\n\r\n.about-feature-list li {\r\n  margin-bottom: 0.5rem;\r\n}\r\n\r\n.about-info a {\r\n  color: #007bff;\r\n  text-decoration: none;\r\n}\r\n\r\n.about-info a:hover {\r\n  text-decoration: underline;\r\n}\r\n\r\n.search-count {\r\n  font-size: 1.2em;\r\n  font-weight: bold;\r\n  color: #007bff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
