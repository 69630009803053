import React from 'react';
import { useParams, Link } from 'react-router-dom';
import './ProjectDetailPage.css';

function ProjectDetailPage({ projects }) {
  const { id } = useParams();
  const project = projects.find(p => p.id === id);

  if (!project) {
    return <div className="project-detail-not-found">Project not found</div>;
  }

  // Split the description into paragraphs
  const descriptionParagraphs = project.description.split('\n').filter(para => para.trim() !== '');

  return (
    <div className="project-detail-page">
      <Link to="/" className="project-detail-back-link">← Back to search results</Link>
      <h2 className="project-detail-title">{project.title}</h2>
      <div className="project-detail-info-container">
        <div className="project-detail-info">
          <div className="project-detail-info-row">
            <div className="project-detail-info-column">
              <div className="project-detail-info-item">
                <span className="project-detail-info-label">Lead Company:</span>
                <span className="project-detail-info-value project-detail-info-value-spaced"> {project.leadCompany}</span>
              </div>
            </div>
            <div className="project-detail-info-column">
              <div className="project-detail-info-item">
                <span className="project-detail-info-label">Completion Date:</span>
                <span className="project-detail-info-value project-detail-info-value-spaced">
                  {project.completionDate || "No data"}
                </span>
              </div>
            </div>
          </div>
          <div className="project-detail-info-item themes-item">
            <span className="project-detail-info-label">Water Innovation Strategy 2050 theme(s): </span>
            <div className="project-detail-info-value themes-container">
              {project.themes.split(',').map((theme, index) => {
                let themeClass = '';
                switch (theme.trim()) {
                  case "Delivering resilient infrastructure systems":
                    themeClass = "theme-resilient-infrastructure";
                    break;
                  case "Protecting and enhancing natural systems":
                    themeClass = "theme-natural-systems";
                    break;
                  case "Providing clean water for all":
                    themeClass = "theme-clean-water";
                    break;
                  case "Achieving net zero carbon":
                    themeClass = "theme-net-zero-carbon";
                    break;
                  case "Providing the services society needs expects and values":
                    themeClass = "theme-society-services";
                    break;
                  case "Taking a whole life approach to responsible consumption and production":
                    themeClass = "theme-responsible-consumption";
                    break;
                  case "Enabling diverse future-ready people and partnership working":
                    themeClass = "theme-diverse-partnerships";
                    break;
                }
                return (
                  <span 
                    key={index} 
                    className={`theme-tag ${themeClass}`}
                  >
                    {theme.trim()}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="project-detail-description">
        {descriptionParagraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      
      {/* External links section */}
      {(project.SpringCaseStudyURL || project.VideoURL || project.PlaybookURL || project.OfwatURL) && (
        <div className="project-detail-info">
          <div className="project-detail-info-item">
            <span className="project-detail-info-label">Useful links: </span>
            <div className="project-detail-info-value">
              {project.SpringCaseStudyURL && (
                <a href={project.SpringCaseStudyURL} target="_blank" rel="noopener noreferrer" className="project-detail-external-link">
                  Case Study (Spring)
                </a>
              )}
              {project.VideoURL && (
                <a href={project.VideoURL} target="_blank" rel="noopener noreferrer" className="project-detail-external-link">
                  Video
                </a>
              )}
              {project.PlaybookURL && (
                <a href={project.PlaybookURL} target="_blank" rel="noopener noreferrer" className="project-detail-external-link">
                  Project Documentation
                </a>
              )}
              {project.OfwatURL && (
                <a href={project.OfwatURL} target="_blank" rel="noopener noreferrer" className="project-detail-external-link">
                  Case Study (Ofwat)
                </a>
              )}
            </div>
          </div>
        </div>
      )}
      
      <div className="project-detail-info">
        <div className="project-detail-info-item">
          <span className="project-detail-info-label">Partners: </span>
          <span className="project-detail-info-value">{project.partners}</span>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetailPage;
