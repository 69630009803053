import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.png'; // Make sure this path is correct

function Header() {
  return (
    <header className="header">
      <a href="https://spring-innovation.co.uk/" target="_blank" rel="noopener noreferrer">
        <img src={logo} alt="Logo" className="logo" />
      </a>
      <h1 className="title">Innovation Snapshot</h1>
      <nav className="nav-links">
        <NavLink to="/" className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} end>
          Home
        </NavLink>
        <NavLink to="/about" className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}>
          About
        </NavLink>
      </nav>
    </header>
  );
}

export default Header;
