// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__EwVYi {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Keep this the same as before */
  background-color: #f8f9fa;
  border-top: 1px solid #e7e7e7;
}

.Footer_footerContent__E5RXg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.Footer_footerText__y-wf5 {
  margin: 0;
  color: #6c757d;
  font-size: 0.9em;
  text-align: left;
}

.Footer_footerButtons__C5KFU {
  display: flex;
  gap: 15px;
}

.Footer_footerButton__waSSl {
  text-decoration: none;
  color: #3D3DFF;
  font-size: 0.9em;
  padding: 5px 10px;
  border: 1px solid #3D3DFF;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.Footer_footerButton__waSSl:hover {
  background-color: #3D3DFF;
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY,EAAE,iCAAiC;EAC/C,yBAAyB;EACzB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,6CAA6C;AAC/C;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".footer {\r\n  position: absolute;\r\n  bottom: 0;\r\n  width: 100%;\r\n  height: 60px; /* Keep this the same as before */\r\n  background-color: #f8f9fa;\r\n  border-top: 1px solid #e7e7e7;\r\n}\r\n\r\n.footerContent {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  max-width: 1200px;\r\n  height: 100%;\r\n  margin: 0 auto;\r\n  padding: 0 20px;\r\n}\r\n\r\n.footerText {\r\n  margin: 0;\r\n  color: #6c757d;\r\n  font-size: 0.9em;\r\n  text-align: left;\r\n}\r\n\r\n.footerButtons {\r\n  display: flex;\r\n  gap: 15px;\r\n}\r\n\r\n.footerButton {\r\n  text-decoration: none;\r\n  color: #3D3DFF;\r\n  font-size: 0.9em;\r\n  padding: 5px 10px;\r\n  border: 1px solid #3D3DFF;\r\n  border-radius: 4px;\r\n  transition: background-color 0.3s, color 0.3s;\r\n}\r\n\r\n.footerButton:hover {\r\n  background-color: #3D3DFF;\r\n  color: #ffffff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__EwVYi`,
	"footerContent": `Footer_footerContent__E5RXg`,
	"footerText": `Footer_footerText__y-wf5`,
	"footerButtons": `Footer_footerButtons__C5KFU`,
	"footerButton": `Footer_footerButton__waSSl`
};
export default ___CSS_LOADER_EXPORT___;
