import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Papa from 'papaparse';
import HomePage from './components/Homepage.jsx';
import ProjectDetailPage from './components/ProjectDetailPage';
import Header from './components/Header.jsx';
import SearchBar from './components/SearchBar.jsx';
import Footer from './components/Footer.jsx'; // Import the new Footer component
import './App.css';
import AboutPage from './components/AboutPage';

function levenshteinDistance(a, b) {
  const matrix = [];

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j] + 1
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

function AppContent() {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('/projects.csv');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const csvText = await response.text();

        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: 'greedy',
          complete: (results) => {
            if (results.errors.length > 0) {
              console.warn('CSV parsing errors:', results.errors);
            }
            console.log('Parsed CSV data:', results.data);
            setProjects(results.data);
            setIsLoading(false);
          },
          error: (error) => {
            console.error('Papa Parse error:', error);
            setError(error);
            setIsLoading(false);
          }
        });
      } catch (error) {
        console.error('Error fetching projects:', error);
        setError(error);
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const calculateRelevanceScore = (project, searchTerm) => {
    console.log('Checking project:', project.title);
    console.log('Search term:', searchTerm);

    const weights = {
      title: 5000,
      description: 200,
      themes: 50,
      leadCompany: 500,
      partners: 10
    };

    const lowercaseSearchTerm = searchTerm.toLowerCase();
    const searchWords = lowercaseSearchTerm.split(/\s+/).filter(word => word.length > 2);

    console.log('Search words:', searchWords);

    const allWordsPresent = searchWords.every(word => 
      Object.entries(weights).some(([field, _]) => {
        const fieldValue = project[field]?.toLowerCase() || '';
        const isPresent = fieldValue.includes(word);
        console.log(`Field ${field} ${isPresent ? 'contains' : 'does not contain'} "${word}"`);
        return isPresent;
      })
    );

    console.log('All words present?', allWordsPresent);

    if (!allWordsPresent) return 0;

    let score = 0;

    for (const [field, weight] of Object.entries(weights)) {
      if (project[field]) {
        const fieldValue = project[field].toLowerCase();
        if (field === 'themes') {
          const themes = fieldValue.split(',');
          for (const theme of themes) {
            const trimmedTheme = theme.trim();
            for (const word of searchWords) {
              if (trimmedTheme === word) {
                score += weight * 10;
              } else if (trimmedTheme.includes(word)) {
                score += weight * 5;
              } else if (levenshteinDistance(trimmedTheme, word) <= 1) {
                score += weight;
              }
            }
          }
        } else {
          if (fieldValue === lowercaseSearchTerm) {
            score += weight * 20;  // Boost for exact field match
          } else {
            const fieldWords = fieldValue.split(/\s+/);
            let fieldScore = 0;
            for (const searchWord of searchWords) {
              let wordScore = 0;
              for (const fieldWord of fieldWords) {
                if (fieldWord === searchWord) {
                  wordScore = Math.max(wordScore, weight * 10);
                } else if (fieldWord.includes(searchWord)) {
                  wordScore = Math.max(wordScore, weight * 5);
                } else if (levenshteinDistance(fieldWord, searchWord) <= 1) {
                  wordScore = Math.max(wordScore, weight);
                }
              }
              fieldScore += wordScore;
            }
            score += fieldScore;
          }
        }
      }
    }

    return score;
  };

  const filteredAndSortedProjects = searchTerm
    ? projects
        .map(project => ({
          ...project,
          relevanceScore: calculateRelevanceScore(project, searchTerm)
        }))
        .filter(project => project.relevanceScore > 0)
        .sort((a, b) => b.relevanceScore - a.relevanceScore)
    : [];  // Return an empty array when there's no search term

  return (
    <div className="App">
      <Header />
      <div className="content-wrapper">
        {isLoading ? (
          <div>Loading projects...</div>
        ) : error ? (
          <div>Error loading projects: {error.message}</div>
        ) : (
          <>
            {location.pathname === '/' && (
              <div className="search-bar-container">
                <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
              </div>
            )}
            <main>
              <Routes>
                <Route 
                  path="/" 
                  element={
                    <HomePage 
                      projects={filteredAndSortedProjects} 
                      searchTerm={searchTerm} 
                    />
                  } 
                />
                <Route path="/about" element={<AboutPage />} />
                <Route 
                  path="/project/:id" 
                  element={
                    <ProjectDetailPage 
                      projects={projects} // Pass full projects array here
                    />
                  } 
                />
              </Routes>
            </main>
          </>
        )}
        <Footer />
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
