// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBar_searchBarContainer__AUwxp {
  margin: 20px auto 40px;  /* Increased bottom margin to 40px */
  max-width: 600px;  /* Increased from 400px to 600px */
  display: flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 4px;  /* Add slight rounding to corners */
}

.SearchBar_searchInput__Yk68W {
  flex-grow: 1;
  padding: 12px 16px;
  font-size: 16px;
  border: none;
  background-color: white;
  color: #333;
}

.SearchBar_searchInput__Yk68W:focus {
  outline: none;
}

.SearchBar_searchInput__Yk68W::placeholder {
  color: #999;
}

.SearchBar_searchButton__YLute {
  padding: 12px 20px;
  background-color: #3D3DFF;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: -1px;  /* Remove any gap between input and button */
}

.SearchBar_searchButton__YLute:hover {
  background-color: #3232CC;
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchBar.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB,GAAG,oCAAoC;EAC7D,gBAAgB,GAAG,kCAAkC;EACrD,aAAa;EACb,wCAAwC;EACxC,gBAAgB;EAChB,kBAAkB,GAAG,mCAAmC;AAC1D;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,sCAAsC;EACtC,iBAAiB,GAAG,4CAA4C;AAClE;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".searchBarContainer {\r\n  margin: 20px auto 40px;  /* Increased bottom margin to 40px */\r\n  max-width: 600px;  /* Increased from 400px to 600px */\r\n  display: flex;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n  overflow: hidden;\r\n  border-radius: 4px;  /* Add slight rounding to corners */\r\n}\r\n\r\n.searchInput {\r\n  flex-grow: 1;\r\n  padding: 12px 16px;\r\n  font-size: 16px;\r\n  border: none;\r\n  background-color: white;\r\n  color: #333;\r\n}\r\n\r\n.searchInput:focus {\r\n  outline: none;\r\n}\r\n\r\n.searchInput::placeholder {\r\n  color: #999;\r\n}\r\n\r\n.searchButton {\r\n  padding: 12px 20px;\r\n  background-color: #3D3DFF;\r\n  color: white;\r\n  border: none;\r\n  cursor: pointer;\r\n  transition: background-color 0.3s ease;\r\n  margin-left: -1px;  /* Remove any gap between input and button */\r\n}\r\n\r\n.searchButton:hover {\r\n  background-color: #3232CC;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBarContainer": `SearchBar_searchBarContainer__AUwxp`,
	"searchInput": `SearchBar_searchInput__Yk68W`,
	"searchButton": `SearchBar_searchButton__YLute`
};
export default ___CSS_LOADER_EXPORT___;
