// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #211E4B;
  color: #FFFFFF;
}

.home-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.page-title {
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 30px;
}

.innovation-project-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 60px 20px !important; /* Increased vertical gap to 40px */
}

/* Responsive adjustments */
@media (max-width: 1000px) {
  .innovation-project-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (max-width: 600px) {
  .innovation-project-grid {
    grid-template-columns: 1fr !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/HomePage.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gDAAgD;EAChD,yBAAyB,EAAE,mCAAmC;AAChE;;AAEA,2BAA2B;AAC3B;EACE;IACE,gDAAgD;EAClD;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF","sourcesContent":["body {\r\n  background-color: #211E4B;\r\n  color: #FFFFFF;\r\n}\r\n\r\n.home-page {\r\n  max-width: 1200px;\r\n  margin: 0 auto;\r\n  padding: 20px;\r\n}\r\n\r\n.page-title {\r\n  text-align: center;\r\n  color: #FFFFFF;\r\n  margin-bottom: 30px;\r\n}\r\n\r\n.innovation-project-grid {\r\n  display: grid;\r\n  grid-template-columns: repeat(3, 1fr) !important;\r\n  gap: 60px 20px !important; /* Increased vertical gap to 40px */\r\n}\r\n\r\n/* Responsive adjustments */\r\n@media (max-width: 1000px) {\r\n  .innovation-project-grid {\r\n    grid-template-columns: repeat(2, 1fr) !important;\r\n  }\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .innovation-project-grid {\r\n    grid-template-columns: 1fr !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
