import React from 'react';
import './AboutPage.css';

function AboutPage() {
  return (
    <div className="about-page">
      <div className="about-info-container">
        <div className="about-info">
          <div className="about-info-item">
            <p>The Innovation Snapshot is a searchable directory of water sector innovation activity, currently in BETA. It is undergoing testing to validate its functionality. In the future, we expect the Snapshot will:</p>
            <ul>
              <li>Help you do more with less by centralising information from multiple sources, saving time and resources.</li>
              <li>Make your workflows more efficient, providing more data on the innovation landscape and augmenting your decision-making process.</li>
              <li>Reduce duplication across the sector by giving visibility into previously trialled innovations.</li>
            </ul>
            <p>At this stage, the Snapshot contains a limited data set for testing and validation purposes. After this initial proof of concept, we will expand the backend data.</p>
          </div>
        </div>
        <div className="about-info">
          <div className="about-info-item">
            <p>For more information or to submit a project, please contact us at <a href="mailto:admin@spring-innovation.co.uk">admin@spring-innovation.co.uk</a>.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
