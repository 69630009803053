// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1.5rem; /* Adjust these values to match your project cards */
  background-color: #211E4B;
  max-width: 1200px; /* Adjust this value to match your project cards container */
  margin: 0 auto; /* Center the header if it's narrower than the viewport */
}

.logo {
  width: 200px;
  height: auto;
}

.title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #FFFFFF;
}

.nav-links {
  display: flex;
  gap: 1rem;
}

.nav-link {
  text-decoration: none;
  color: #FFFFFF;
  padding-bottom: 2px;
  border-bottom: 1.5px solid transparent;  /* Changed from 2px to 1px */
  transition: color 0.3s, border-bottom-color 0.3s;
  font-size: 0.95rem;
}

.nav-link:hover {
  color: #54ff9c;
}

.nav-link.active {
  color: #54ff9c;
  border-bottom-color: #54ff9c;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,oBAAoB,EAAE,oDAAoD;EAC1E,yBAAyB;EACzB,iBAAiB,EAAE,4DAA4D;EAC/E,cAAc,EAAE,yDAAyD;AAC3E;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,2BAA2B;EAC3B,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,mBAAmB;EACnB,sCAAsC,GAAG,4BAA4B;EACrE,gDAAgD;EAChD,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,4BAA4B;AAC9B","sourcesContent":[".header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding: 2rem 1.5rem; /* Adjust these values to match your project cards */\r\n  background-color: #211E4B;\r\n  max-width: 1200px; /* Adjust this value to match your project cards container */\r\n  margin: 0 auto; /* Center the header if it's narrower than the viewport */\r\n}\r\n\r\n.logo {\r\n  width: 200px;\r\n  height: auto;\r\n}\r\n\r\n.title {\r\n  position: absolute;\r\n  left: 50%;\r\n  transform: translateX(-50%);\r\n  text-align: center;\r\n  color: #FFFFFF;\r\n}\r\n\r\n.nav-links {\r\n  display: flex;\r\n  gap: 1rem;\r\n}\r\n\r\n.nav-link {\r\n  text-decoration: none;\r\n  color: #FFFFFF;\r\n  padding-bottom: 2px;\r\n  border-bottom: 1.5px solid transparent;  /* Changed from 2px to 1px */\r\n  transition: color 0.3s, border-bottom-color 0.3s;\r\n  font-size: 0.95rem;\r\n}\r\n\r\n.nav-link:hover {\r\n  color: #54ff9c;\r\n}\r\n\r\n.nav-link.active {\r\n  color: #54ff9c;\r\n  border-bottom-color: #54ff9c;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
