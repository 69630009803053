import React from 'react';
import { Link } from 'react-router-dom';
import './ProjectCard.css';

function ProjectCard({ project }) {
  const truncateDescription = (text, maxLength = 300) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, text.lastIndexOf(' ', maxLength)) + '...';
  };

  const completionDate = project.completionDate ? project.completionDate : "No data";

  return (
    <div className="project-card">
      <h3 className="project-title">
        <Link to={`/project/${project.id}`}>{project.title}</Link>
      </h3>
      <p className="project-description">{truncateDescription(project.description)}</p>
      <div className="project-details">
        <p className="project-company">{project.leadCompany}</p>
        <p className="project-completion"><strong>Completion date:</strong> {completionDate}</p>
      </div>
    </div>
  );
}

export default ProjectCard;
