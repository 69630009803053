import React from 'react';
import styles from './Footer.module.css';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <p className={styles.footerText}>&copy; 2024 Spring Innovation. All rights reserved.</p>
        <div className={styles.footerButtons}>
          <a href="https://www.linkedin.com/company/spring-innovation/" target="_blank" rel="noopener noreferrer" className={styles.footerButton}>
            LinkedIn
          </a>
          <a href="https://spring-innovation.co.uk/spring-mailing-list-sign-up/" target="_blank" rel="noopener noreferrer" className={styles.footerButton}>
            Join Email List
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
